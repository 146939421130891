body.andys-charts-debug {
	background-color: #f7f7f7;
	margin: 0;
	font-family: sans-serif;
}

body.andys-charts-debug .andy-chart-root {
	max-width: 1000px;
	margin: 100px auto;
	background-color: #fff;
}

body.andys-charts-debug .andy-chart-root:empty {
	margin: 0 auto;
}

body.andys-charts-debug--dark {
	background-color: #444;
}

body.andys-charts-debug .andy-chart-root--dark {
	background-color: #111;
}
