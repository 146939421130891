.chart {
	max-width: 1000px;
}

.main, .inner {
	position: relative;
}

.canvas {
	display: block;
}

.valueAxis {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #fff;
}
