.info {
	position: absolute;
	background-color: #fff;
	pointer-events: none;
	min-width: 120px;
	font-size: 14px;
	text-align: left;
	padding: .75rem;
	border-radius: 3px;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,.4);
	z-index: 3;
}

.noInfo {
	display: none;
}

.tracks {
	list-style-type: none;
	margin: 0;
	padding: 0;
	color: #666;
	font-weight: bold;
	line-height: 1.5em;
}

.tracks li {
	padding: 0 0 .25rem;
}

.playInfo {
	font-size: 13px;
	line-height: 1.5em;
	color: #999;
	margin: 0;
}
