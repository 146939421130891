.main {
	color: #666;
	font-weight: bold;
}

.main.darkMain {
	color: #ccc;
}

.artist {
	color: #777;
}

.darkContainer .artist {
	color: #aaa;
}
