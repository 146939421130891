.area.fadedArea {
	opacity: 0;
}

.line {
	stroke-width: 1.5;
	fill: none;
}

.line.fadedLine {
	stroke: rgba(0, 0, 0, .125);
}

.line.fadedLine.darkLine {
	stroke: rgba(255, 255, 255, .125);
}

.line.thinLine {
	stroke-width: 1;
}
