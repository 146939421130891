.axisLine {
	stroke: #ccc;
	shape-rendering: crispEdges;
	fill: none;
}

.axisLine.darkAxisLine {
	stroke: #444;
}

.axisLabel {
	fill: #aaa;
	font-size: 11px;
}

.axisValueLabel {
	text-anchor: end;
}

.axisLabel.darkAxisLabel {
	fill: #666;
}

.axisLabel.hiddenLabel {
	opacity: 0;
	pointer-events: none;
}
